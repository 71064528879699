/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-19 21:40:38
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-19 21:43:19
 */
import CountAgent from './CountAgent.vue'
export default CountAgent
