/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-19 21:40:34
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-25 15:11:31
 */
import { getNormalWeek, numberFormat } from '@/plugins/utils'
export default {
  name: 'CountAgent',
  data () {
    return {
      date: [],
      queryForm: {
        start_date: '',
        end_date: '',
        field_type: '',
        sort_type: '',
        keyword: ''
      }
    }
  },
  watch: {
    date (val, old) {
      if (val) {
        this.$set(this.queryForm, 'start_date', val[0])
        this.$set(this.queryForm, 'end_date', val[1])
      }
    }
  },
  methods: {
    tableSort (val) {
      this.queryForm.field_type = val.fieldType
      this.queryForm.sort_type = val.sort_type
      this.tableSearch(true, true)
    },
    numFormat (val) {
      return numberFormat(val)
    },
    tableSearch (reset, sort) {
      if (!sort) {
        this.queryForm.field_type = ''
        this.queryForm.sort_type = ''
      }
      if (reset) {
        this.$refs['w-table'].resetList()
      } else {
        this.$refs['w-table'].getList()
      }
    }
  },
  created () {
    this.date = getNormalWeek()
  }
}
