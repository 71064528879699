/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-19 21:23:51
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-19 21:51:06
 */
import CountAgent from './CountAgent'
import CountApp from './CountApp'
export default {
  name: 'Count',
  components: {
    CountAgent,
    CountApp
  },
  data () {
    return {
      activeName: 'agent'
    }
  }
}
