/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-19 21:40:22
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-19 21:42:55
 */
import CountApp from './CountApp.vue'
export default CountApp
